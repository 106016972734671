import React, { useState } from 'react';
import { Select, MenuItem, Box, InputLabel, FormControl, Typography, Button, TextField, Chip, Tabs, Tab, CircularProgress } from '@mui/material';
import axios from '../axiosConfig';
import './EditProductPage.css';

const categories = ["Vitamins", "Minerals", "Lifestyle", "Herbs", "Supplements"];
const subcategories = [
  "Bone Health", "Brain Health", "Children's Health", "Diabetic Health",
  "Digestive Health", "Hair, Skin & Nail Health", "Heart & Circulatory Health",
  "Immune Health", "Liver & Detox Health", "Men's Health", "Sleep & Relaxation",
  "Vision Health", "Women's Health"
];

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="tab-content">
          {children}
        </Box>
      )}
    </div>
  );
};

const AddProductPage = () => {
  const [product, setProduct] = useState({
    name: '',
    category: '',
    subcategory: '',
    tags: [],
    quantity: 0,
    description: '',
    price: 0,
    ingredients: '',
    directions: '',
    warning: '',
    packSize: '',
    features: [],
  });
  const [mainImage, setMainImage] = useState('');
  const [selectedThumbnail, setSelectedThumbnail] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [newTag, setNewTag] = useState('');
  const [newFeature, setNewFeature] = useState('');
  const [newImages, setNewImages] = useState([]);
  const [displayImages, setDisplayImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const adminToken = localStorage.getItem('adminToken');

  const handleThumbnailClick = (src, index) => {
    setMainImage(src);
    setSelectedThumbnail(index);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleAddTag = () => {
    if (newTag && !product.tags.includes(newTag)) {
      setProduct({ ...product, tags: [...product.tags, newTag] });
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setProduct({ ...product, tags: product.tags.filter(tag => tag !== tagToRemove) });
  };

  const handleAddFeature = () => {
    if (newFeature && !product.features.includes(newFeature)) {
      setProduct({ ...product, features: [...product.features, newFeature] });
      setNewFeature('');
    }
  };

  const handleRemoveFeature = (featureToRemove) => {
    setProduct({ ...product, features: product.features.filter(feature => feature !== featureToRemove) });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
        const mimeType = file.type;
        const base64WithMime = `data:${mimeType};base64,${base64String}`;
        setDisplayImages([...displayImages, base64WithMime]); // For display
        setNewImages([...newImages, file]); // For upload
        setMainImage(base64WithMime);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = (imageToRemove) => {
    setDisplayImages(displayImages.filter(image => image !== imageToRemove));
    setNewImages(newImages.filter(image => URL.createObjectURL(image) !== imageToRemove));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('name', product.name);
      formData.append('category', product.category);
      formData.append('subcategory', product.subcategory);
      formData.append('tags', product.tags);
      formData.append('quantity', product.quantity);
      formData.append('description', product.description);
      formData.append('price', product.price);
      formData.append('ingredients', product.ingredients);
      formData.append('directions', product.directions);
      formData.append('warning', product.warning);
      formData.append('inStock', product.quantity > 0);
      formData.append('packSize', product.packSize);
      formData.append('features', product.features);
      newImages.forEach(image => formData.append('images', image));

      await axios.post('/products', formData, {
        headers: {
          Authorization: adminToken ? `Bearer ${adminToken}` : '',
          'Content-Type': 'multipart/form-data'
        }
      });

      alert('Product added successfully');
    } catch (error) {
      alert('Product not added. Please fill in all the fields.');
      console.error('Error adding product:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="product-page">
      <Typography variant="h3">Add Product</Typography>

      <Box className="productdisplay">
        <Box className="productdisplay-left">
          <Box className="productdisplay-img-list">
            {displayImages.map((src, index) => (
              <Box key={index} position="relative">
                <img
                  src={src}
                  alt={`Thumbnail ${index + 1}`}
                  className={`thumbnail ${selectedThumbnail === index ? 'selected' : ''}`}
                  onClick={() => handleThumbnailClick(src, index)}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => handleRemoveImage(src)}
                  style={{ position: 'absolute', top: 5, right: 5 }}
                >
                  X
                </Button>
              </Box>
            ))}
            <Button
              variant="contained"
              component="label"
              color="primary"
              className="upload-button"
            >
              Add Image
              <input
                type="file"
                hidden
                onChange={handleImageUpload}
              />
            </Button>
          </Box>
          <Box className="productdisplay-img">
            <img className="productdisplay-main-img" src={mainImage} alt="Main Product" />
          </Box>
        </Box>
        <Box className="productdisplay-right">
          <Box display="flex" alignItems="center" gap={5}>
            <TextField
              label="Product Name"
              variant="outlined"
              margin="normal"
              name="name"
              value={product.name}
              onChange={handleInputChange}
            />
            <TextField
              label="Pack Size"
              variant="outlined"
              margin="normal"
              name="packSize"
              value={product.packSize}
              onChange={handleInputChange}
            />
            <FormControl>
              <InputLabel>Category</InputLabel>
              <Select
                label="Category"
                name="category"
                value={product.category}
                onChange={handleInputChange}
                sx={{ minWidth: 120 }}
              >
                {categories.map((category) => (
                  <MenuItem value={category} key={category}>{category}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Subcategory</InputLabel>
              <Select
                label="Subcategory"
                name="subcategory"
                value={product.subcategory}
                onChange={handleInputChange}
                sx={{ minWidth: 120 }}
              >
                {subcategories.map((subcategory) => (
                  <MenuItem value={subcategory} key={subcategory}>{subcategory}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="product-chips" sx={{ marginTop: "20px" }}>
            <Typography variant="h5">Tags</Typography>
            {product.tags.map((tag, index) => (
              <Chip
                label={tag}
                key={index}
                onDelete={() => handleRemoveTag(tag)}
                color="secondary"
                style={{ margin: 4 }}
              />
            ))}
            <TextField
              label="Add Tag"
              variant="outlined"
              margin="normal"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleAddTag()}
              sx={{ margin: "20px" }}
            />
          </Box>
          <Box className="product-chips" sx={{ marginTop: "20px" }}>
            <Typography variant="h5">Features</Typography>
            {product.features.map((feature, index) => (
              <Chip
                label={feature}
                key={index}
                onDelete={() => handleRemoveFeature(feature)}
                color="secondary"
                style={{ margin: 4 }}
              />
            ))}
            <TextField
              label="Add Feature"
              variant="outlined"
              margin="normal"
              value={newFeature}
              onChange={(e) => setNewFeature(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleAddFeature()}
              sx={{ margin: "20px" }}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={5}>
            <TextField
              label="Price"
              variant="outlined"
              margin="normal"
              name="price"
              value={product.price}
              onChange={handleInputChange}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={5}>
            <TextField
              label="Quantity"
              variant="outlined"
              margin="normal"
              name="quantity"
              value={product.quantity}
              onChange={handleInputChange}
            />
            
          </Box>
        </Box>
      </Box>
      <Box className="product-tabs">
        <Tabs value={tabIndex} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          <Tab label="Description" />
          <Tab label="Supplements Facts" />
          <Tab label="Directions" />
          <Tab label="Warnings" />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            name="description"
            value={product.description}
            onChange={handleInputChange}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <TextField
            label="Supplements Facts"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            name="ingredients"
            value={product.ingredients}
            onChange={handleInputChange}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <TextField
            label="Directions"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            name="directions"
            value={product.directions}
            onChange={handleInputChange}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={3}>
          <TextField
            label="Warnings"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            name="warning"
            value={product.warning}
            onChange={handleInputChange}
          />
        </TabPanel>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} />}
      >
        {loading ? 'Adding...' : 'Add Product'}
      </Button>
    </Box>
  );
};

export default AddProductPage;
