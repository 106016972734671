import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Grid, Paper, Snackbar, Alert, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import './Checkout.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination as SwiperPagination, Navigation } from 'swiper/modules';

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cartItems, promoCode, promoDiscount, totalAmount } = location.state || { cartItems: [], promoCode: '', promoDiscount: 0, totalAmount: 0 };
  const [paymentMethod, setPaymentMethod] = useState('COD');
  const [address, setAddress] = useState({
    name: '',
    address: '',
    postalCode: '5400',
    phone: '',
    city: '',
    email: ''
  });
  const [cardDetails, setCardDetails] = useState({
    cardNumber: '',
    cardName: '',
    expiryDate: '',
    cvv: '',
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [deliveryCharges, setDeliveryCharges] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get('/user/profile', {
            headers: { Authorization: `Bearer ${token}` },
          });
          const { name, address, postalCode, phone, city, email } = response.data;
          setAddress({ name, address, phone, city, email });
        } catch (error) {
          console.error('Error fetching user data', error);
        }
      }
    };

    const calculateAndFetchDeliveryCharges = async () => {
      const subtotal = cartItems.reduce((sum, item) => sum + item.price * item.amount, 0);

      if (subtotal > 15000) {
        setDeliveryCharges(0);
      } else {
        try {
          const response = await axios.post('/orders/shipping');
          setDeliveryCharges(response.data.shippingFee);
        } catch (error) {
          console.error('Error fetching shipping fee', error);
        }
      }
    };

    fetchUserData();
    calculateAndFetchDeliveryCharges(); // Calculate and fetch delivery charges
  }, [cartItems]);

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleCardDetailsChange = (event) => {
    const { name, value } = event.target;
    setCardDetails({ ...cardDetails, [name]: value });
  };

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setAddress({ ...address, [name]: value });
  };

  const redeemPromoCode = async () => {
    if (promoCode) {
      try {
        const response = await axios.post('/promocode/redeem', { code: promoCode });
        if (response.data.code === promoCode) {
          return true;
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
          return false;
        }
      } catch (error) {
        setSnackbarMessage('Error redeeming promo code');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const isPromoCodeValid = await redeemPromoCode();
    if (!isPromoCodeValid) {
      setSnackbarMessage('Invalid promo code');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setLoading(false);
      return;
    }

    const subtotal = cartItems.reduce((sum, item) => sum + item.price * item.amount, 0);
    const discount = cartItems.reduce((sum, item) => sum + (item.discount * item.amount * item.price / 100), 0) + (subtotal * (promoDiscount / 100));
    const totalAfterDiscount = subtotal - discount;

    // Ensure the delivery charges are recalculated before placing the order
    const finalDeliveryCharges = totalAfterDiscount > 15000 ? 0 : deliveryCharges;

    const summary = {
      subtotal,
      discount,
      deliveryCharges: finalDeliveryCharges,
      total: totalAfterDiscount + finalDeliveryCharges,
    };

    const orderDetails = {
      products: cartItems.map(item => ({
        product: item.id,
        name: item.name,
        price: (item.price - item.price * item.discount / 100),
        count: item.amount,
        image: item.image,
      })),
      transaction_id: null,
      amount: summary.total,
      address: address.address,
      city: address.city,
      phoneNumber: address.phone,
      email: address.email,
      type: paymentMethod,
      cardDetails: paymentMethod === 'Card' ? cardDetails : null,
      hasDiscount: promoCode ? true : false,
      promoCode: promoCode ? promoCode : null,
      summary,
    };

    try {
      const token = localStorage.getItem('token');
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      const response = await axios.post('/orders', orderDetails, { headers });
      console.log('Order placed successfully', response.data);
      localStorage.removeItem('cart');
      setSnackbarMessage('Order placed successfully! Redirecting to home...');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);

      // Send purchase event to Google Analytics using gtag
      window.gtag('event', 'purchase', {
        transaction_id: 0, // Assuming the response contains an orderId
        value: summary.total,
        currency: "PKR", // Adjust the currency as per your application
        items: cartItems.map(item => ({
          item_id: item.product,
          item_name: item.name,
          price: (item.price - item.price * item.discount / 100),
          quantity: item.amount,
          category: item.category, // Assuming you have a category field
          // Optional fields
          // brand: item.brand, // if available
          // variant: item.variant, // if available
        })),
        shipping: summary.deliveryCharges,
        tax: 0, // Adjust if you have tax calculations
        coupon: promoCode ? promoCode : undefined
      });

      setTimeout(() => navigate('/'), 3000);
    } catch (error) {
      setSnackbarMessage('Error placing order');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      console.error('Error placing order:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="checkout-container">
      <Box className="carousel-container">
        <Swiper
          loop={true}
          pagination={{ clickable: true }}
          navigation={true}
          className="simpleCarousel"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          slidesPerGroup={1}
          modules={[SwiperPagination, Navigation, Autoplay]}
        >
          <SwiperSlide key={1}>
            <img src={`https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1722010103/login_page-0001_heyup5.jpg`} alt={`Sale ${1}`} style={{ objectFit: "contain" }} />
          </SwiperSlide>
        </Swiper>
      </Box>
      <Typography variant="h4" align="center" gutterBottom>Checkout</Typography>
      <form onSubmit={handleSubmit} className="checkout-form">
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Paper className="checkout-section">
              <Typography variant="h6" gutterBottom>Shipping Address</Typography>
              <TextField
                label="Full Name"
                variant="outlined"
                fullWidth
                margin="normal"
                name="name"
                value={address.name}
                onChange={handleAddressChange}
                required
              />
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                margin="normal"
                name="address"
                value={address.address}
                onChange={handleAddressChange}
                required
              />
              <TextField
                label="Postal Code"
                variant="outlined"
                fullWidth
                margin="normal"
                name="postalCode"
                value={address.postalCode}
                onChange={handleAddressChange}
                required
              />
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                margin="normal"
                name="phone"
                value={address.phone}
                onChange={handleAddressChange}
                required
              />
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                margin="normal"
                name="city"
                value={address.city}
                onChange={handleAddressChange}
                required
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                name="email"
                value={address.email}
                onChange={handleAddressChange}
                required
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className="checkout-section">
              <Typography variant="h6" gutterBottom>Payment Method</Typography>
              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Select Payment Method</FormLabel>
                <RadioGroup
                  name="paymentMethod"
                  value={paymentMethod}
                  onChange={handlePaymentMethodChange}
                  row
                >
                  <FormControlLabel value="COD" control={<Radio />} label="Cash on Delivery" />
                  <FormControlLabel value="Card" control={<Radio />} label="Online Card Payment" disabled />
                </RadioGroup>
              </FormControl>
              {paymentMethod === 'Card' && (
                <Box className="card-details">
                  <TextField
                    label="Card Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="cardNumber"
                    value={cardDetails.cardNumber}
                    onChange={handleCardDetailsChange}
                    required
                    placeholder="1234 5678 9012 3456"
                  />
                  <TextField
                    label="Name on Card"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="cardName"
                    value={cardDetails.cardName}
                    onChange={handleCardDetailsChange}
                    required
                    placeholder="John Doe"
                  />
                  <Box className="card-details-row">
                    <TextField
                      label="Expiry Date (MM/YY)"
                      variant="outlined"
                      margin="normal"
                      name="expiryDate"
                      value={cardDetails.expiryDate}
                      onChange={handleCardDetailsChange}
                      required
                      className="card-details-expiry"
                      placeholder="12/23"
                    />
                    <TextField
                      label="CVV"
                      variant="outlined"
                      margin="normal"
                      name="cvv"
                      value={cardDetails.cvv}
                      onChange={handleCardDetailsChange}
                      required
                      className="card-details-cvv"
                      placeholder="123"
                    />
                  </Box>
                </Box>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className="checkout-section">
              <Typography variant="h6" gutterBottom>Order Summary</Typography>
              {cartItems.map(item => (
                <Box className="order-summary-item" key={item.id}>
                  <Typography>{item.name}</Typography>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {item.discount > 0 ? (
                      <>
                        <Typography
                          variant="body1"
                        >{`Rs ${(item.price).toFixed(0)}`}</Typography>
                      </>
                    ) : (
                      <Typography variant="body1" sx={{ color: 'red' }}>{`Rs ${item.price * item.amount}`}</Typography>
                    )}
                  </div>
                </Box>
              ))}
              <Box className="order-summary-item">
                <Typography>Subtotal</Typography>
                <Typography>
                  Rs {(cartItems.reduce((sum, item) => sum + item.price * item.amount, 0)).toFixed(0)}
                </Typography>
              </Box>
              <Box className="order-summary-item">
                <Typography>Discount </Typography>
                <Typography>
                  - Rs {(
                    cartItems.reduce((sum, item) => sum + (item.discount * item.amount * item.price / 100), 0) + 
                    (cartItems.reduce((sum, item) => sum + item.price * item.amount, 0) * (promoDiscount / 100))
                  ).toFixed(0)}
                </Typography>
              </Box>
              <Box className="order-summary-item">
                <Typography>Delivery Charges</Typography>
                <Typography>Rs {deliveryCharges.toFixed(0)}</Typography>
              </Box>
              <Box className="order-summary-item total">
                <Typography variant="h6">Total</Typography>
                <Typography variant="h6">Rs {(cartItems.reduce((sum, item) => sum + item.price * item.amount, 0) - (cartItems.reduce((sum, item) => sum + (item.discount * item.amount * item.price / 100), 0) + (cartItems.reduce((sum, item) => sum + item.price * item.amount, 0) * (promoDiscount / 100))) + deliveryCharges).toFixed(0)}</Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Place Order'}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Checkout;
