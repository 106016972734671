import React, { useEffect, useState } from "react";
import axios from '../axiosConfig';
import { Box, Typography, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, Select, FormControl, InputLabel, Button, Snackbar, Pagination, TextField, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import MuiAlert from '@mui/material/Alert';
import "./ListProduct.css";

const ListProduct = () => {
  const [products, setProducts] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [quantityFilter, setQuantityFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const productsPerPage = 10;

  const adminToken = localStorage.getItem('adminToken');
  const headers = adminToken ? { Authorization: `Bearer ${adminToken}` } : '';

  const fetchProducts = async (page = 1, category = '', quantity = '', term = '') => {
    setLoading(true);
    try {
      const endpoint = term ? '/products/search' : '/products';
      const response = await axios.get(endpoint, {
        params: {
          page,
          limit: productsPerPage,
          category,
          quantityFilter: quantity,
          search: term
        },
        headers
      });
      setProducts(response.data.products || response.data || []);
      setTotalPages(response.data.totalPages || Math.ceil(response.data.length / productsPerPage));
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts(currentPage, categoryFilter, quantityFilter, searchTerm);
  }, [currentPage, categoryFilter, quantityFilter, searchTerm]);

  const removeProduct = async (id) => {
    try {
      await axios.delete(`/products/${id}`, { headers });
      fetchProducts(currentPage, categoryFilter, quantityFilter, searchTerm);
      setSnackbarMessage('Product removed successfully');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error removing product:", error);
      setSnackbarMessage('Error removing product');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCategoryChange = (event) => {
    setCategoryFilter(event.target.value);
    setCurrentPage(1);
  };

  const handleQuantityChange = (event) => {
    setQuantityFilter(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('/products/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: adminToken ? `Bearer ${adminToken}` : '',
        }
      });
      setSnackbarMessage('Products imported successfully');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      fetchProducts(currentPage, categoryFilter, quantityFilter, searchTerm);
    } catch (error) {
      console.error("Error importing products:", error);
      setSnackbarMessage('Error importing products: ' + (error.response?.data?.error || error.message));
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const csvHeaders = [
    { label: "Products", key: "dummy" }, // Dummy header
    { label: "name", key: "name" },
    { label: "category", key: "category" },
    { label: "subcategory", key: "subcategory" },
    { label: "tags", key: "tags" },
    { label: "quantity", key: "quantity" },
    { label: "description", key: "description" },
    { label: "price", key: "price" },
    { label: "ingredients", key: "ingredients" },
    { label: "directions", key: "directions" },
    { label: "warning", key: "warning" },
    { label: "inStock", key: "inStock" },
    { label: "packSize", key: "packSize" },
    { label: "features", key: "features" }
  ];

  return (
    <Box className="listproduct">
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" gutterBottom>All Products List</Typography>
        <Box display="flex" alignItems="center">
          {/* <Button variant="contained" color="primary" style={{ marginRight: '10px' }}>
            <CSVLink 
              data={products.map(product => ({
                dummy: "", // Add dummy data
                name: product.name,
                category: product.category,
                subcategory: product.subcategory,
                tags: product.tags,
                quantity: product.quantity,
                description: product.description,
                price: product.price,
                ingredients: product.ingredients,
                directions: product.directions,
                warning: product.warning,
                inStock: product.inStock,
                packSize: product.packSize,
                features: product.features
              }))} 
              headers={csvHeaders} 
              filename="products.csv" 
              style={{ color: 'white', textDecoration: 'none' }}
            >
              Export CSV
            </CSVLink>
          </Button> */}
          <Button variant="contained" color="secondary" component="label" style={{ marginRight: '10px' }}>
            Import CSV
            <input
              type="file"
              hidden
              onChange={handleFileUpload}
            />
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2} style={{ gap: '20px' }}>
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel>Sort by Category</InputLabel>
          <Select
            value={categoryFilter}
            onChange={handleCategoryChange}
            label="Category"
          >
            <MenuItem value=""><em>All</em></MenuItem>
            <MenuItem value="Vitamins">Vitamins</MenuItem>
            <MenuItem value="Minerals">Minerals</MenuItem>
            <MenuItem value="Herbals">Herbals</MenuItem>
            <MenuItem value="Supplements">Supplements</MenuItem>
            <MenuItem value="Lifestyle">Lifestyle</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel>Sort by Quantity</InputLabel>
          <Select
            value={quantityFilter}
            onChange={handleQuantityChange}
            label="Quantity"
          >
            <MenuItem value=""><em>All</em></MenuItem>
            <MenuItem value="<10">{'< 10 units'}</MenuItem>
            <MenuItem value="outOfStock">Out of Stock</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TextField
        label="Search Products"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        size="small"
        style={{ width: '300px', margin: '20px 20px', borderRadius: '50px' }}
      />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper} className="listproduct-table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Inventory</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Subcategory</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow key={product._id}>
                    <TableCell>
                      <img className="listproduct-product-icon" src={`${product.images[0].url.split('/upload/')[0]}/upload/f_auto/${product.images[0].url.split('/upload/')[1]}`} alt={product.name} />
                    </TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.price}</TableCell>
                    <TableCell>{product.quantity}</TableCell>
                    <TableCell>{product.category}</TableCell>
                    <TableCell>{product.subcategory}</TableCell>
                    <TableCell>
                      <Link to={`/admin/editproduct/${product._id}`}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Link>
                      <IconButton onClick={() => { removeProduct(product._id) }}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            className="pagination"
          />
        </>
      )}
    </Box>
  );
};

export default ListProduct;
